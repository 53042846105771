<template>
  <div style="margin:-10px;">
    <div>
      <el-carousel :height="bannerHeight + 'px'">
        <el-carousel-item v-for="item in img_list" :key="item">
          <div :style="'width:100%;height:'+bannerHeight+'px;background-image:url('+item+');background-size: cover'">
          </div>
          <!-- <img :src="item" alt /> -->
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="about-us">
      <div class='title'>
        <div>
          <img :src="require('@/assets/index/Home_about01.png')" />
        </div>
        <div class="sub-title">
          <img :src="require('@/assets/index/Home_about02.png')" />
        </div>
        <div>
          <div class='content'>广东蓝羽新材料科技有限公司始创于2003年，前身是江门市蓝羽建筑粘合剂实业有限公司，是国内年产量领先的墙纸墙布配套基膜、湿胶生产</div>
          <div class='content'>商之一。近年来，公司专注于发展墙面系统一体化解决方案，为客户提供集墙面基底处理、配套辅料、装饰壁材、施工服务的一体化服务平台，坚持
          </div>
          <div class='content'>“科技创新、品质领先、开放合作”，致力于为客户提供高品质、全方位墙面装修解决方案。</div>
          <!-- <div class='content'>引进国际先进的生产设备和完善的研发实验室，以及拥有一批高级专业开发和管理人员</div> -->
        </div>
        <div style="padding-top:100px;">
          <router-link :to="'/about'">
            <span class="btn">了解更多</span>
          </router-link>
        </div>
      </div>
    </div>
    <div style="padding:150px 50px;">
      <el-row>
        <el-col :span="8">
          <router-link :to="'/content-detail?title=发展历程'">
            <div class="title" @mouseleave="mouseenter3 = false" @mouseenter="mouseenter3 = true">
              <img v-if='mouseenter3 == true' :src="require('@/assets/index/about_03_1.png')" />
              <img v-else :src="require('@/assets/index/about_03.png')" />
              <div class='sub-title2'>
                <span class='blue-text'>发展</span><span style='color:#000'>历程</span>
              </div>
              <div class='content2'>
                <div>蓝羽成立，并推出“墙纸基膜”这一产品</div>
                <div>历史性地改变了墙纸施工习惯</div>
              </div>
            </div>
          </router-link>
        </el-col>
        <el-col :span="8">
          <router-link :to="'/content-detail?title=蓝羽优势'">
            <div class="title" @mouseleave="mouseenter4 = false" @mouseenter="mouseenter4 = true">
              <img v-if='mouseenter4 == true' :src="require('@/assets/index/about_04_1.png')" />
              <img v-else :src="require('@/assets/index/about_04.png')" />
              <div class='sub-title2'>
                <span class='blue-text'>蓝羽</span><span style='color:#000'>优势</span>
              </div>
              <div class='content2'>
                <div>拥有实体生产基地，占地面积25亩</div>
                <div>拥有全套墙纸胶粉、液态胶、墙纸基膜的自动化生产设备</div>
                <div>产能17000吨/年</div>
              </div>
            </div>
          </router-link>
        </el-col>
        <el-col :span="8">
          <router-link :to="'/content-detail?title=资质荣誉'">
            <div class="title" @mouseleave="mouseenter5 = false" @mouseenter="mouseenter5 = true">
              <img v-if='mouseenter5 == true' :src="require('@/assets/index/about_05_1.png')" />
              <img v-else :src="require('@/assets/index/about_05.png')" />
              <div class='sub-title2'>
                <span class='blue-text'>资质</span><span style='color:#000'>认证</span>
              </div>
              <div class='content2'>
                <div>产品质量及环保性能的孜孜不倦追求得到了认可</div>
                <div>获得了由国家环境保护部环境中心和</div>
                <div>中环联合(北京)认证中心联合颁发的中国环保标志产品十环认证</div>
              </div>
            </div>
          </router-link>
        </el-col>
      </el-row>
    </div>
    <!-- 产品分类 -->
    <div class="products">
      <div class='title'>
        <div>
          <img :src="require('@/assets/index/home_product01.png')" />
        </div>
        <div class="sub-title">
          <img :src="require('@/assets/index/home_product02.png')" />
        </div>
      </div>
      <div>
        <el-row>
          <el-col :span="12">
            <div>
              <router-link :to="'/products'">
                <div class='product-category1' @mouseleave="mouseenterProduct1 = false"
                  @mouseenter="mouseenterProduct1 = true">
                  <div>
                    <img v-if="mouseenterProduct1" :src="require('@/assets/index/Home_products_01_1.png')" />
                    <img v-else :src="require('@/assets/index/Home_products_01.png')" />
                  </div>
                  <div class="category-title">
                    墙布/墙纸辅料
                  </div>
                  <div class="category-detail">
                    <div>墙纸胶、墙纸基膜、墙纸粉、墙布胶</div>
                    <div>行业品牌 环保安全 品质稳定</div>
                  </div>
                </div>
              </router-link>
            </div>
          </el-col>
          <el-col :span="12">
            <div>
              <router-link :to="'/products'">
                <div class='product-category2' @mouseleave="mouseenterProduct2 = false"
                  @mouseenter="mouseenterProduct2 = true">
                  <div>
                    <img v-if="mouseenterProduct2" :src="require('@/assets/index/Home_products_02_1.png')" />
                    <img v-else :src="require('@/assets/index/Home_products_02.png')" />
                  </div>
                  <div class="category-title">
                    成品A+艺术漆
                  </div>
                  <div class="category-detail">
                    <div>绿色环保 防污 防撞 防开裂</div>
                    <div>B1级阻燃 持久耐用</div>
                  </div>
                </div>
              </router-link>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 工程案例 -->
    <div class='case'>
      <div class='title'>
        <div>
          <img :src="require('@/assets/index/home_case01.png')" />
        </div>
        <div class="sub-title">
          <img :src="require('@/assets/index/home_case02.png')" />
        </div>
      </div>
      <div>
        <el-row>
          <el-col v-for="(item,index) in cases" :span='8' :key="index">
            <router-link :to="`/content-detail?id=${item.id}`">
              <div class='case-list' @mouseenter="enter(index)" @mouseleave="move">
                <div v-if='choosedIndex != index' class='mask'>
                  <span class='case-name'>{{item.title}}</span>
                </div>
                <img :src="item.cover" style="width:100%;" />
              </div>
            </router-link>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 新闻资讯 -->
    <div class='news'>
      <div class='title'>
        <div>
          <img :src="require('@/assets/index/home_news01.png')" />
        </div>
        <div class="sub-title">
          <img :src="require('@/assets/index/home_news02.png')" />
        </div>
      </div>
      <div style="padding:0px 10%">
        <el-row>
          <el-col :span="12">
            <div>
              <img :src="newsFirst.cover" style="width:100%" />
              <div class='news-title'>
                <span class='blue-text'>{{newsFirst.title}}</span>
                <!-- <span class='date'>{{leaveTime(newsFirst.createdTime)}}</span> -->
              </div>
              <div class='news-sub-title'>
                {{newsFirst.subTitle}}
              </div>
              <div class='to-detail'>
                <router-link :to="`/content-detail?id=${newsFirst.id}`"><span style="color:#3DB4DA">查看详细
                    >></span></router-link>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div style="padding-left: 10%">
              <div class='news-right' v-for="(item,index) in news" :key="index">
                <el-row>
                  <el-col :span="8">
                    <div>
                      <img :src="item.cover" style="width:100%" />
                    </div>
                  </el-col>
                  <el-col :span="16">
                    <div style='padding-left:10px;'>
                      <div>
                        <span class='blue-text'>{{item.title}}</span>
                        <!-- <span class='date'>{{leaveTime(item.createdTime)}}</span> -->
                      </div>
                      <div class='news-sub-title'>
                        {{item.subTitle}}
                      </div>
                      <div class='to-detail'>
                        <router-link :to="`/content-detail?id=${item.id}`"><span style="color:#3DB4DA">查看详细
                            >></span></router-link>
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <el-divider></el-divider>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    name: "Banner",

    data() {
      return {
        img_list: [],
        bannerHeight: 2000,
        screenWidth: 0,
        choosedIndex: -1,
        mouseenter3: false,
        mouseenter4: false,
        mouseenter5: false,
        mouseenterProduct1: false,
        mouseenterProduct2: false,
        cases: [],
        newsFirst: '',
        news: []
      };
    },

    created() {
      //如果是手机，切换到手机端
      if (this._isMobile()) {
        window.location = 'http://lanyu-china.com/h5/index.html#/'
      }
      //首页轮播
      this.axios.get('/api/albums/by-title/website-banners').then((res) => {
        for (let i of res.data.data.pictures) {
          this.img_list.push(i.image)
        }
      })
      //工程案例
      this.axios.get('/api/contents', {
        params: {
          "@filter": `entity.getCategory().getName() == '工程案例'`,
          limit: 6,
          "@order": "entity.createdTime|DESC"
        }
      }).then((res) => {
        this.cases = res.data.data
      })
      //新闻资讯
      this.axios.get('/api/contents', {
        params: {
          "@filter": `entity.getCategory().getName() == '资讯动态'`,
          limit: 4,
          "@order": "entity.createdTime|DESC"
        }
      }).then((res) => {
        for (let i in res.data.data) {
          if (i == 0) {
            this.newsFirst = res.data.data[i]
          } else {
            this.news.push(res.data.data[i])
          }
        }
      })
      //百度统计
      var _hmt = _hmt || [];
      (function () {
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?9f58d2368ace2270eab597c05208ed75";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
      })();
    },

    methods: {
      setSize: function () {
        // 通过浏览器宽度(图片宽度)计算高度
        this.bannerHeight = (1024 / 1920) * this.screenWidth;
      },
      enter(index) {
        this.choosedIndex = index
      },
      move() {
        this.choosedIndex = -1
      },
      leaveTime(value) {
        return moment(value).format('YYYY-MM-DD HH:mm');
      },
      // 判断手机端还是PC端
      _isMobile() {
        console.log('device：', navigator.userAgent)
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        console.log('flag', flag)
        if (flag != null) {
          return true
        } else {
          return false
        }
      }
    },

    mounted() {
      this.screenWidth = window.innerWidth;
      this.setSize();
      window.onresize = () => {
        this.screenWidth = window.innerWidth;
        this.setSize();
      };
    }
  };
</script>

<style scoped>
  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: #000;
    opacity: 0.5;
    filter: alpha(opacity=40);
    z-index: 5;
    text-align: center;
    color: #F3F3F3;
  }

  .news-title {
    padding-top: 10px;
  }

  .date {
    font-size: 14px;
    color: #606266;
    float: right
  }

  .news {
    padding: 150px 0px 230px 0px;
    background-repeat: repeat;
    background-image: url("https://lyyuncai.lanyu-china.com/e10c3246-a032-4a00-8f80-1a5730e3d44f.png");
  }

  .case-list {
    margin: 8px;
    position: relative;
  }

  .case-name {
    font-size: 23px;
  }

  .case {
    padding: 100px 0px;
  }

  .products {
    margin-top: 50px;
    padding: 150px 0px;
    background-image: url("https://lyyuncai.lanyu-china.com/c225e731-0b0c-4b3a-adc6-5908ce714ca6.png");
  }

  .about-us {
    margin-top: 80px;
    background-image: url("https://lyyuncai.lanyu-china.com/03086262-ae4d-4813-a1e3-e9602302ffb0.png");
  }

  .news-sub-title {
    color: #606266;
    font-size: 14px;
    font-size: 12px;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 50px;
    padding-top: 10px;
  }

  .news-right {
    padding-bottom: 20px;
  }

  .title {
    text-align: center;
  }

  .sub-title {
    margin-top: 10px;
    margin-bottom: 40px;
  }

  .btn {
    background-color: #3DB4DA;
    color: white;
    padding: 15px 50px;
    border-radius: 30px;
  }

  .to-detail {
    color: #3DB4DA;
    font-size: 13px;
    padding-top: 20px;
  }

  .blue-text {
    color: #3DB4DA;
    font-weight: bold;
  }

  .content {
    font-size: 20px;
    color: #606266;
    margin: 5px;
  }

  .sub-title2 {
    font-size: 20px;
    font-weight: bold;
    padding: 25px;
  }

  .blue-text {
    color: #3DB4DA;
  }

  .content2 {
    color: #303133;
    font-size: 16px;
  }

  .product-category1 {
    background-color: white;
    text-align: center;
    width: 50%;
    margin-left: 40%;
    border-radius: 15px;
    padding: 15% 0px;
  }

  .product-category2 {
    background-color: white;
    text-align: center;
    width: 50%;
    padding: 15% 0px;
    margin-left: 10%;
    border-radius: 15px;
  }

  .category-title {
    font-size: 23px;
    font-weight: bold;
    padding-top: 50px;
    color: #303133;
  }

  .category-detail {
    font-size: 16px;
    padding-top: 30px;
    color: #303133
  }

  a {
    text-decoration: none;
  }

  .banner {
    width: 1000%;
    margin: auto;
  }
</style>