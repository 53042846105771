<template>
  <div >
    <div class='header'>
      <div class="logo">
        <img :src="require('@/assets/logo.png')" class="logo-image" />
      </div>
      <div class="mune">
        <span v-if="$route.fullPath == '/'" class="active">网站首页</span>
        <span v-else class="default" ><router-link :to="'/'">网站首页</router-link></span>
        <span v-if="$route.fullPath == '/about'" class="active">关于蓝羽</span>
        <span v-else class="default"><router-link :to="'/about'">关于蓝羽</router-link></span>
        <span v-if="$route.fullPath == '/products'" class="active">产品中心</span>
        <span v-else class="default"><router-link :to="'/products'">产品中心</router-link></span>
        <span v-if="$route.fullPath == '/content'" class="active">资讯动态</span>
        <span v-else class="default"><router-link :to="'/content'">资讯动态</router-link></span>
        <span v-if="$route.fullPath == '/case'" class="active">工程案例</span>
        <span v-else class="default"><router-link :to="'/case'">工程案例</router-link></span>
        <span v-if="$route.fullPath == '/join'" class="active">招商加盟</span>
        <span v-else class="default"><router-link :to="'/join'">招商加盟</router-link></span>
        <!-- <span v-if="$route.fullPath == '/school'" class="active">蓝羽学堂</span>
        <span v-else class="default"><router-link :to="'/school'">蓝羽学堂</router-link></span> -->
        <span v-if="$route.fullPath == '/contact'" class="active">联系我们</span>
        <span v-else class="default"><router-link :to="'/contact'">联系我们</router-link></span>
      </div>
      </div>
    <router-view/>
    <div style="background-color: #F3F3F3;height:10px"></div>
    <div class='footer'>
        <div>
          <el-row>
            <el-col :span="14">
              <div>
                <el-row>
                  <el-col :span="6">
                    <div>
                      <div class='title'>关于我们</div>
                      <div class='line'></div>
                      <router-link :to="'/content-detail?title=关于蓝羽'"><div class='sub-title'>蓝羽简介</div></router-link>
                      <router-link :to="'/content-detail?title=发展历程'"><div class='sub-title'>发展历程</div></router-link>
                      <router-link :to="'/content-detail?title=资质荣誉'"><div class='sub-title'>资质荣誉</div></router-link>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div>
                      <div class='title'>蓝羽产品</div>
                      <div class='line'></div>
                      <div v-for='(item,index) in categories' :key='index'>
                        <router-link :to="`/products?category=${item.id}`"><div class='sub-title'>{{item.name}}</div></router-link>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div>
                      <div class='title'>技术服务</div>
                      <div class='line'></div>
                      <router-link :to="'/content-detail?title=技术优势'"><div class='sub-title'>技术优势</div></router-link>
                      <router-link :to="'/content-detail?title=服务承诺'"><div class='sub-title'>服务承诺</div></router-link>
                      <router-link :to="'/case'"><div class='sub-title'>工程案例</div></router-link>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div>
                      <div class='title'>联系我们</div>
                      <div class='line'></div>
                      <router-link :to="'/content-detail?title=加入我们'"><div class='sub-title'>加入我们</div></router-link>
                      <router-link :to="'/content-detail?title=微信咨询'" ><div class='sub-title' >微信咨询</div></router-link>
                      <router-link :to="'/content-detail?title=联系方式'"><div class='sub-title'>联系方式</div></router-link>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :span="10">
              <div style='padding-left:50%'>
                <div style="color:#303133">
                  业务咨询热线
                </div>
                <div class='phone'>
                  400-830-7028
                </div>
                 <div style="color:#303133">
                  技术服务热线
                </div>
                <div class='phone'>
                  400-885-6633
                </div>
                <div>
                  <img :src="require('@/assets/QQ.png')" @click='showQQ = true' />
                  <el-dialog
                  title="客服QQ"
                  :visible.sync="showQQ"
                  width="30%">
                    <span>客服QQ号码:2880366017</span>
                    <span slot="footer" class="dialog-footer">
                      <el-button type="primary" @click="showQQ = false">确 定</el-button>
                    </span>
                  </el-dialog>
                  <img :src="require('@/assets/WeChat.png')" style='padding-left:15px;' @click='showWechat = true'/>
                  <el-dialog
                  title="扫码添加客服微信"
                  :visible.sync="showWechat"
                  width="30%">
                    <div style="text-align:center">
                      <img :src="require('@/assets/wechat_code.jpeg')" />
                    </div>
                  </el-dialog>
                  <!-- <img :src="require('@/assets/mail.png')" style="padding-left:15px;"/> -->
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <el-divider></el-divider>
        <div style='text-align:center'>
          <span class='text'>
            地址：广东省江门市高新区33号地（清澜路）2号厂房
          </span>
          <span class='text' style='padding-left:50px;'>传真：0750-3870701</span>
            <span class='text' style="padding-left:50px;">版权所有: 广东蓝羽新材料科技有限公司</span>
            <span class='text' style="padding-left:50px;">备案号：</span>
            <a href="https://beian.miit.gov.cn/#/Integrated/index">
              <span class='text'>粤ICP备15018012号</span>
            </a>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
      return {
        showQQ: false,
        showWechat: false,
        showEmail: false,
        index: 13,
        categories: []
      }
    },
    created(){
      //查一级产品分类
      this.axios.get('/api/categories',{params: {
        "@filter": `entity.getType().getSlug() == 'PRODUCT_CATEGORY_TOP' && entity.getName() != '礼品'`,
        "@order": "entity.sequence|ASC"
      }}).then((res) => {
        console.log('categories',res.data.data)
        this.categories = res.data.data
      })
    },
    mounted(){
      console.log(this.$route)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .phone{
    color: #3DB4DA;
    font-size: 23px;
    padding-bottom:20px;
  }
  .line{
    background-color: #3DB4DA;
    height: 3px;
    width: 30px;
    margin:5px 0px 15px 0px;
  }
  .title{
    font-size: 18px;
  }
  .sub-title{
    font-size: 15px;
    padding-top: 10px;
    color: #606266;
  }
  .text{
    font-size: 14px;
    color: #606266;
  }
  .footer{
    padding: 50px 10%;
  }
  .header{
    position: fixed;
    top:0px;
    left:0px;
    background-color: rgba(00, 00, 00, 0.5);
    width:100%;
    display: flex;
    height: 80px;
    z-index: 99;
  }
  .logo{
    align-items: center;
    width: 30%;
  }
  .logo-image{
    height: 68px;
    padding: 5px 0px;
    margin-left: 35%;
    z-index: 999;
  }
  .mune{
    width: 80%;
    line-height: 75px;
    text-align: center;
  }
  .active{
    color:white;
    border-radius: 20px;
    background: #3DB4DA;
    padding: 10px 25px;
    font-size:15px;
    margin: 5px 10px;
  }
  .default{
    color:white;
    border-radius: 20px;
    padding: 5px 15px;
    font-size:15px;
    margin: 5px 10px;
  }
  a{
    text-decoration:none;
    color: white;
  }
</style>
